import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var cookieValue = window.localStorage.getItem('cookiesAccepted')
var cookieBoolean = null

if (cookieValue === 'true') {
  cookieBoolean = true
} else if (cookieValue === 'false') {
  cookieBoolean = false
}

export default new Vuex.Store({
  state: {
    cookiesAccepted: cookieBoolean
  },
  mutations: {
    cookiesAccepted (state, accept) {
      state.cookiesAccepted = accept
    }
  },
  actions: {
    setCookiesAccepted (context, value) {
      context.commit('cookiesAccepted', value)
      window.localStorage.setItem('cookiesAccepted', value)
    }
  },
  modules: {
  }
})
