<template>
  <v-app>
    <v-navigation-drawer app temporary v-model="drawer">
      <v-list-item>
        <v-list-item-content> Haus Kim </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title> Startseite </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ferienwohnung_sabrina">
          <v-list-item-title> Ferienwohnung Sabrina </v-list-item-title>
        </v-list-item>
        <v-list-item to="/ferienwohnung_svenja">
          <v-list-item-title> Ferienwohnung Svenja </v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="secondary">
          <v-img
            class="mx-auto"
            width="calc(100% - 50px)"
            src="./assets/logo_font_bottom.svg"
          >
          </v-img>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Haus Kim</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <v-footer inset padless class="secondary" dark>
      <v-card flat tile class="text-center" width="100%" color="secondary">
        <v-card-text class="justify-center">
          <v-img
            class="mx-auto"
            max-width="200px"
            src="./assets/logo_font_bottom.svg"
          >
          </v-img>
          <v-btn text class="text-capitalize" :to="'/impressum'">
            Impressum
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          {{ new Date().getFullYear() }} - <strong>Haus Kim</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <cookie-consent></cookie-consent>
  </v-app>
</template>

<script>
import cookieConsentVue from './components/cookie-consent.vue'

export default {
  name: 'App',
  data: () => ({
    drawer: false
  }),
  components: {
    'cookie-consent': cookieConsentVue
  }
  /*
  , watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Haus Kim auf Sylt'
    }
  }
  */
}
</script>

<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";

.v-card__title {
  word-break: break-word !important;
}
</style>
