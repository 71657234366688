import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home.vue')
const Impress = () => import('../views/Impress.vue')
const Sabrina = () => import('../views/Sabrina.vue')
const Svenja = () => import('../views/Svenja.vue')
const p404 = () => import('../views/p404.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impress,
    meta: {
      title: 'Impressum'
    }
  },
  {
    path: '/ferienwohnung_sabrina',
    name: 'Ferienwohnung Sabrina',
    component: Sabrina,
    meta: {
      title: 'Ferienwohnung Sabrina'
    }
  },
  {
    path: '/ferienwohnung_svenja',
    name: 'Ferienwohnung Svenja',
    component: Svenja,
    meta: {
      title: 'Ferienwohnung Svenja'
    }
  },
  {
    path: '*',
    name: '404 - Page not Found',
    component: p404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 70 } } // navbar as a height of 70px
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Haus Kim auf Sylt'
  next()
})

export default router
