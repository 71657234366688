<template>
  <v-footer v-if="cookiesAccepted === null" fixed color="primary" dark>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-row dense>
            <v-col cols="12"> Wir nutzen Cookies für Google Maps. </v-col>
            <v-col cols="12" sm="6">
              <v-btn block @click="declineCookies">{{ decline }}</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn block @click="acceptCookies" class="">{{ accept }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'cookie-consent',
  props: {
    accept: { type: String, default: 'Akzeptieren' },
    decline: { type: String, default: 'Ablehnen' },
    visible: { type: Boolean, default: true }
  },
  computed: {
    ...mapState({
      cookiesAccepted: state => state.cookiesAccepted
    })
  },
  methods: {
    acceptCookies () {
      this.$emit('accept')
      this.$store.dispatch('setCookiesAccepted', true)
    },
    declineCookies () {
      this.$emit('decline')
      this.$store.dispatch('setCookiesAccepted', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
