import 'material-design-icons-iconfont'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#009688',
        secondary: '#424242',
        accent: '#ff6e40'
      }
    }
  }
})
